import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ChestIconColoured from "../images/chest-icon-coloured.svg";

function AboutPage() {
  return (
    <Layout>
      <SEO
        keywords = { [`LawChest`, `about`] }
        title = "About"
      />

      <section
        className = "flex flex-col md:flex-row items-center"
      >
        <div
          className = "w-full md:w-2/3 md:mr-8"
        >
          <blockquote
            className = "border-l-4 border-gray-900 text-sm pl-4 text-justify"
          >
            LawChest wants to improve accuracy and transparency of legal information. We believe we can improve just outcomes in the Australian legal system by equipping parties with tools to work with an overwhelming amount of information. We hope to progressively add to our tools – or, the “LawChest” – over time and would happily receive your ideas.
          </blockquote>

          <cite
            className = "font-bold mt-4 text-right text-xs uppercase block"
          >
            – LawChest Creators
          </cite>
        </div>
        <figure
          className = "w-1/3"
        >
          <img
            alt = "Chest"
            src = { ChestIconColoured }
          />
        </figure>
      </section>
      <div
        className = "pt-8"
      >
        <p
          className = "text-center"
        >
          Icons made by
          { ' ' }
          <a
            className = "font-bold no-underline"
            href = "https://www.flaticon.com/authors/freepik"
            title = "Freepik"
          >
            Freepik
          </a>
          ,
          { ' ' }
          <a
            className = "font-bold no-underline"
            href = "https://www.flaticon.com/authors/surang"
            title = "surang"
          >
            surang
          </a>
          ,
          { ' ' }
          <a
            className = "font-bold no-underline"
            href = "https://www.flaticon.com/authors/iconixar"
            title = "iconixar"
          >
            iconixar
          </a>
          ,
          { ' ' }
          <a
            className = "font-bold no-underline"
            href = "https://www.flaticon.com/authors/flat-icons"
            title = "Flat Icons"
          >
            Flat Icons
          </a>
          ,
          { ' ' }
          <a
            className = "font-bold no-underline"
            href = "https://www.flaticon.com/authors/eucalyp"
            title = "Eucalyp"
          >
            Eucalyp
          </a>
          ,
          { ' ' }
          and
          { ' ' }
          <a
            className = "font-bold no-underline"
            href = "https://www.flaticon.com/authors/geotatah"
            title = "geotatah"
          >
            geotatah
          </a>
          { ' ' }
          from
          { ' ' }
          <a
            className = "font-bold no-underline"
            href = "https://www.flaticon.com/"
            title = "Flaticon"
          >
            www.flaticon.com
          </a>
        </p>
      </div>
    </Layout>
  );
}

export default AboutPage;
